<!--  -->
<template>
    <div class="homecont" >
        正在加载中.....
    </div>
  </template>
  
  <script>
//   import { APILogin } from "../api/APILogin";
//   import {Toast} from 'vant';

  export default {
    name: "login",
    components: {},
    data() {
      return {
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    filters: {},
    //方法集合
    methods: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = ""
      const userAgent = window.navigator.userAgent;
      if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
          console.log('移动端')
          this.$router.push({ path: "/shouhome"})
      } else {
        console.log('pc端')
        this.$router.push({ path: "/home"})
      }
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less" scoped>
  .homecont-btm {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 550px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,.2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    &-btn {
      width: 40%;
      height: 45px;
      border-radius: 30px;
      background-color: rgba(230, 50, 46,);
      color: #fff;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      animation: pulse infinite; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 2s; /* don't forget to set a duration! */
  
      span {
        margin-left: 3px;
      }
    }
    &-btn::before {
      content: "";
      display: block;
      width: 25px;
      height: 50px;
      background: rgba(255, 255, 255, 0.3);
      transform: skewX(-25deg);
      position: absolute;
      left: -35px;
      animation: guang infinite 2s ease-in;
    }
    @keyframes guang {
      50% {
        left: -45px;
      }
      100% {
        left: 110px;
      }
    }
  
  
  
  }
  .homecont-font {
    width: 96%;
    padding: 10px 0;
    margin: 0 auto;
    &-title {
        font-size: 20px;
        margin-bottom: 10px;
        letter-spacing: 3px;
        // animation: flipInY infinite; /* referring directly to the animation's @keyframe declaration */
        // animation-duration: 2s; /* don't forget to set a duration! */
    }
    &-content {
      text-align: justify;
    }
    
  }
  .homecont-swiper {
    background: rgba(230, 50, 46, 0.08);
    width: 96%;
    padding: 10px 0;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 10px;
    &-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      opacity: .4;
      color: rgba(230, 50, 46);
      width: 96%;
      margin: 0 auto;
    }
    &-box {
      border-radius: 50px;
      background-color: rgba(230, 50, 46, .3);
      opacity: 1;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,.2);
      animation: lightSpeedInLeft infinite; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 4s; /* don't forget to set a duration! */
    }
  }
   .phoneFormBtn {
      width: 190px;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      margin: 20px auto;
      margin-bottom: 0;
      animation: pulse infinite; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 2s; /* don't forget to set a duration! */
    }
    .btn2 {
      width: 188px;
      height: 43px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid rgb(230, 50, 46);
      background-color: rgb(230, 50, 46);
      color: #fff;
    }
    
    .btn2 span:nth-child(1) {
      width: 184px;
      height: 3px;
      border-radius: 0 10px 10px 0;
      position: absolute;
      top: -3px;
      left: -184px;
      animation: bordertop 2s linear infinite;
      background: linear-gradient(to right, rgb(230, 50, 46), #fff);
    }
    .btn2 span:nth-child(2) {
      width: 3px;
      height: 40px;
      border-radius: 0 0 10px 10px;
      position: absolute;
      top: -43px;
      right: -3px;
      animation: borderright 2s linear 1s infinite;
      background: linear-gradient(to bottom, rgb(230, 50, 46), #fff);
    }
    .btn2 span:nth-child(3) {
      width: 184px;
      height: 3px;
      border-radius: 10px 0 0 10px;
      position: absolute;
      top: 40px;
      right: -184px;
      animation: borderbottom 2s linear 2s infinite;
      background: linear-gradient(to left, rgb(230, 50, 46), #fff);
    }
    .btn2 span:nth-child(4) {
      width: 3px;
      height: 40px;
      border-radius: 10px 10px 0 0;
      position: absolute;
      top: 43px;
      left: -3px;
      animation: borderleft 2s linear 3s infinite;
      background: linear-gradient(to top, rgb(230, 50, 46), #fff);
    }
      @keyframes bordertop {
        100% {
          left: 100px;
        }
      }
  
      @keyframes borderright {
        100% {
          top: 40px;
        }
      }
  
      @keyframes borderbottom {
        100% {
          right: 100px;
        }
      }
  
      @keyframes borderleft {
        100% {
          top: -43px;
        }
      }
  
  
    .homecont-form {
      width: 90%;
      padding: 10px;
      margin: 0 auto;
      margin-bottom: 10px;
      border-radius: 4px;
      padding-bottom: 20px;
      overflow: hidden;
      background-color: #fff;
      animation: opacitt 2s forwards,huxi 4s linear infinite alternate;
      @keyframes opacitt {
        0% {
          opacity: 0;
        }
        100% {
        opacity: 1;
        }
      }
      @keyframes huxi {
        from {
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,.2);
        }
        to {
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0,.2);
        }
      }
      &-title {
        font-size: 20px;
        margin-bottom: 30px;
        letter-spacing: 3px;
        // animation: flipInY infinite; /* referring directly to the animation's @keyframe declaration */
        // animation-duration: 2s; /* don't forget to set a duration! */
      }
    }
  
  
  
    .homecont {
      width: 100%;
      min-height: 100%;
      background: #ffffff;
      position: relative;
      max-width: 550px;
      margin: 0 auto;
      padding-bottom: 70px;
      &-img {
        width: 100%;
        height: auto;
        position: relative;
        margin-bottom: 10px;
        animation: bounce; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 800ms; /* don't forget to set a duration! */
        img{
          width: 100%;
        }
        &-title {
          position: absolute;
          top: 6px;
          left: 6px;
          font-size: 22px;
        }
      }
    }
  </style>
  <style lang="less">
  .homecont-form {
    .van-cell-group--inset {
      margin: 0 auto;
    }
  }
  .van-loading {
    margin: 45% auto;
    width: 200px;
  }
  </style>
  